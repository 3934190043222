import React from 'react';

interface Question {
  role?: string;
  query?: string;
  icon: string;
  label: string;
  text?: string;
}

interface AnimatedCardProps {
  question: Question;
  index: number;
  setCurrentRole?: (role: string) => void;
  newConversation: (query: string | undefined, role: string | undefined) => void;
}

const AnimatedCard: React.FC<AnimatedCardProps> = ({ 
  question, 
  index, 
  setCurrentRole, 
  newConversation 
}) => {
  return (
    <div
      key={index}
      style={{
        cursor: "pointer",
        width: "160px", 
        backgroundColor: "#F9FAFB",
        padding: "12px",
        borderRadius: "12px",
        border: "1px solid #E5E7EB",
        height: "200px", 
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.3s ease",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.02)",
        position: "relative",
        overflow: "hidden"
      }}
      onClick={(e) => {
        const ripple = document.createElement("div");
        const rect = e.currentTarget.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        ripple.style.width = ripple.style.height = `${size}px`;
        ripple.style.left = `${e.clientX - rect.left - size/2}px`;
        ripple.style.top = `${e.clientY - rect.top - size/2}px`;
        ripple.style.position = "absolute";
        ripple.style.borderRadius = "50%";
        ripple.style.backgroundColor = "rgba(79, 70, 229, 0.1)";
        ripple.style.transform = "scale(0)";
        ripple.style.animation = "ripple 0.6s linear";
        e.currentTarget.appendChild(ripple);
        
        setTimeout(() => {
          ripple.remove();
          if (setCurrentRole && question.role) {
            setCurrentRole(question.role);
          }
          newConversation(question.query, question.role);
        }, 150);
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-5px)";
        e.currentTarget.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.05)";
        e.currentTarget.style.borderColor = "#D1D5DB";
        e.currentTarget.style.backgroundColor = "#FFFFFF";
        
        const iconContainer = e.currentTarget.querySelector('.icon-container') as HTMLElement;
        const icon = e.currentTarget.querySelector('.icon') as HTMLElement;
        const title = e.currentTarget.querySelector('.card-title') as HTMLElement;
        const desc = e.currentTarget.querySelector('.card-description') as HTMLElement;
        const topAccent = e.currentTarget.querySelector('.top-accent') as HTMLElement;
        
        if (iconContainer) {
          iconContainer.style.transform = "scale(1.05)";
          iconContainer.style.boxShadow = "0 5px 15px rgba(79, 70, 229, 0.15)";
        }
        if (icon) icon.style.transform = "scale(1.1) rotate(5deg)";
        if (title) title.style.color = "#4F46E5";
        if (desc) desc.style.color = "#4B5563";
        if (topAccent) topAccent.style.height = "4px";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.02)";
        e.currentTarget.style.borderColor = "#E5E7EB";
        e.currentTarget.style.backgroundColor = "#F9FAFB";
        
        const iconContainer = e.currentTarget.querySelector('.icon-container') as HTMLElement;
        const icon = e.currentTarget.querySelector('.icon') as HTMLElement;
        const title = e.currentTarget.querySelector('.card-title') as HTMLElement;
        const desc = e.currentTarget.querySelector('.card-description') as HTMLElement;
        const topAccent = e.currentTarget.querySelector('.top-accent') as HTMLElement;
        
        if (iconContainer) {
          iconContainer.style.transform = "scale(1)";
          iconContainer.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.05)";
        }
        if (icon) icon.style.transform = "scale(1) rotate(0deg)";
        if (title) title.style.color = "#1F2937";
        if (desc) desc.style.color = "#6B7280";
        if (topAccent) topAccent.style.height = "0px";
      }}
    >
      {/* Top gradient accent */}
      <div
        className="top-accent"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "0px",
          background: "linear-gradient(90deg, #4F46E5, #8B5CF6)",
          transition: "height 0.3s ease",
        }}
      />
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          position: "relative",
          zIndex: 2
        }}
      >
        <div
          className="icon-container"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            padding: "10px",
            marginBottom: "16px", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
            border: "1px solid #F3F4F6",
            transition: "all 0.3s ease",
          }}
        >
          <img
            className="icon"
            src={question.icon}
            alt="logo"
            style={{
              maxWidth: "30px",
              maxHeight: "30px",
              transition: "transform 0.3s ease",
            }}
          />
        </div>
        <h3
          className="card-title"
          style={{
            fontSize: "14px",
            color: "#1F2937",
            textAlign: "center",
            margin: "0 0 10px 0", 
            transition: "color 0.3s ease",
          }}
        >
          {question.label}
        </h3>
        <p
          className="card-description"
          style={{
            fontSize: "12px",
            color: "#6B7280",
            textAlign: "center",
            margin: "0",
            transition: "color 0.3s ease",
          }}
        >
          {question.text || "Click to start"}
        </p>
      </div>
      
      <style>
        {`
          @keyframes ripple {
            to {
              transform: scale(4);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AnimatedCard;