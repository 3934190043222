import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logout } from "../../../lib/APIService";

interface Nav {
  label: string;
  icon: React.ReactNode;
  path: string;
}

interface NavigationProps {
  setSideBar: (sideBar: string) => void;
}

export const navItems: Nav[] = [
  { path: "/home/chat", icon: "chat", label: "Chat" },
  {
    path: "/home/knowledge-bases",
    icon: "library_books",
    label: "Knowledge Base",
  },
  { path: "/home/tools", icon: "functions", label: "Tools" },
  { path: "/home/toolgroups", icon: "tab_group", label: "Tool Groups" },
  { path: "/home/credentials", icon: "security", label: "Credentials" },
  { path: "/home/agents", icon: "robot_2", label: "Agents" },

  // { path: "/home/upload", icon: "upload", label: "Upload" },
  { path: "/home/flows", icon: "flowsheet", label: "Flows" },
];

const Navigation = ({ setSideBar }: NavigationProps) => {
  const location = useLocation();
  const [activeValue, setActiveValue] = useState<string>("Chat");
  const [viewDrawer, setViewDrawer] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const currentNavItem = navItems.find((nav) =>
      location.pathname.startsWith(nav.path)
    );
    if (currentNavItem) {
      setActiveValue(currentNavItem.path as string);
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        setViewDrawer(false);
      }
    };

    // Add event listener when dialog is open
    if (viewDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or dialog is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [location.pathname, viewDrawer]);

  return (
    <div className="small-margin">
      <nav className="left surface small-margin">
        <header>
          <div
            className="no-border top-margin bottom-margin medium round tiny-padding wave cursor-pointer"
            onClick={(viewDrawer) => setViewDrawer(true)}
          >
            <i>menu</i>
          </div>
        </header>
        {navItems.map((navitem) => (
          <a
            key={navitem.label}
            className={activeValue.includes(navitem.path) ? "active" : ""}
            onClick={() => setActiveValue(navitem.label)}
            href={navitem.path}
          >
            <i className="medium">{navitem.icon}</i>
            <span className="no-line">{navitem.label}</span>
          </a>
        ))}
        <footer className="footer">
          <button className="circle" style={{ background: "none" }}>
            {localStorage.getItem("image") &&
            localStorage.getItem("image") !== null &&
            localStorage.getItem("image") !== "undefined" ? (
              <img
                className="small"
                // src={localStorage.getItem("image")!}
                src="https://media.licdn.com/dms/image/v2/D4E03AQG7lgSrKUA4uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713718672676?e=2147483647&v=beta&t=-zTO23cPd5u8oC-EmcfTS1gdWDzTAmA26E-KkrFte9o"
                alt={localStorage.getItem("firstName")?.charAt(0)!}
              />
            ) : (
              <i className="circle extra secondary">account_circle</i>
            )}
            <menu
              className="no-wrap"
              style={{
                bottom: "100%",
                left: "-50%",
                transform: "translateY(-8px)",
              }}
            >
              <a href="/" onClick={() => logout()}>
                Sign Out
              </a>
            </menu>
          </button>
          {/* Ashok */}
          <p className="rail-username">{localStorage.getItem("firstName")}</p>
        </footer>
      </nav>

      <dialog
        ref={dialogRef}
        className={
          viewDrawer
            ? "left no-padding active surface"
            : "left no-padding surface"
        }
      >
        <nav className="drawer" style={{ height: "100%" }}>
          <header>
            <nav>
              <i className="extra">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 332.3 392.83"
                >
                  <path
                    className="cls-1"
                    d="M467.22,431.53c9.63-2,17,1.2,20.11,10.59,2.44,7.3.19,14.16-6.87,18.13-8.8,4.94-15.65,3.62-21.92-5.55-.4-.58-.65-1.3-1.07-1.8-15.47-18.47-24.64-19.46-36.82-9.57-12.6,10.23-7.55,27.1,1,39.42,7.6,11,10.53,21.39,3.94,33.16a25.59,25.59,0,0,1-36.67,8.44c-13.71-9.54-16.67-22.59-7.76-36.64,4.49-7.1,4.72-9.54,9.92-22.2,2.9-7,8-18-3.9-22.15-10.74-3.76-16.28.26-24.49,12.42-2.43,3.59-3.34,9.15-6,13.09-5.3,7.76-13.22,10.78-22.14,7.47-9.77-3.62-14.13-11.49-12.63-21.78,1.37-9.47,7.29-14.79,17-15.91,6.62-.76,13.52,3.24,19.86-3.13,8-8,18.66-15.29,14.76-28-3.72-12.08-12.08-21.76-25.85-24.49-13.61-2.7-20.29-9.93-14.09-23.89,2.35-5.27,7.56-8.34,13.82-8.08,8,.34,12.18,5.19,15.26,12.32C374,389.84,400.63,398.48,426,384.63c7.82-4.26,12.05-9.34,13.38-19.18,2-14.74,13-21.46,27.74-18.87,12.88,2.26,19.75,11.65,19,24.14-.82,13.43-9.06,21.67-23.22,22.69-4.79.35-10.28-2.43-14.24,1.49C442.5,401,432,404.54,433.87,416,435.2,424,453.81,432.38,467.22,431.53Z"
                    transform="translate(-238.77 -241.02)"
                  />
                  <path
                    className="cls-1"
                    d="M404.92,633.85a50.77,50.77,0,0,1-29.14-9.15,321,321,0,0,1-137-262.8,327,327,0,0,1,1.78-34,51.36,51.36,0,0,1,33.38-42.68l113.63-41.14a51.22,51.22,0,0,1,34.71,0l113.64,41.14a51.37,51.37,0,0,1,33.37,42.68,325.81,325.81,0,0,1,1.78,34,321,321,0,0,1-137,262.8A50.74,50.74,0,0,1,404.92,633.85Zm0-369.22a27.4,27.4,0,0,0-9.33,1.63L282,307.39a27.63,27.63,0,0,0-17.93,23,301.36,301.36,0,0,0-1.65,31.55A297.39,297.39,0,0,0,389.31,605.36a27.34,27.34,0,0,0,31.21,0A297.38,297.38,0,0,0,547.45,361.9a300.37,300.37,0,0,0-1.65-31.55,27.61,27.61,0,0,0-17.93-23L414.23,266.26A27.25,27.25,0,0,0,404.92,264.63Z"
                    transform="translate(-238.77 -241.02)"
                  />
                </svg>
              </i>
              <h6 className="min">Fusion Security</h6>
              {/* <i className="extra">
                <img src="/TrustGenie.png" alt="logo"></img>
              </i>
              <h6 className="min">Trust Genie</h6> */}

              <button
                className="transparent circle small max"
                onClick={(viewDrawer) => setViewDrawer(false)}
              >
                <i>close</i>
              </button>
            </nav>
          </header>
          {navItems.map((navitem) => (
            <a
              key={navitem.label}
              className={activeValue.includes(navitem.path) ? "active" : ""}
              onClick={() => setActiveValue(navitem.label)}
              href={navitem.path}
            >
              <i className="medium">{navitem.icon}</i>
              <span className="max">{navitem.label}</span>
            </a>
          ))}

          <footer
            className="footer"
            style={{
              width: "90%",
            }}
          >
            <hr style={{ width: "100%" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {localStorage.getItem("image") &&
                localStorage.getItem("image") !== null &&
                localStorage.getItem("image") !== "undefined" ? (
                  <i className="extra">
                    <img
                      // src={localStorage.getItem("image")!}
                      // src="https://media.licdn.com/dms/image/v2/D4E03AQG7lgSrKUA4uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713718672676?e=2147483647&v=beta&t=-zTO23cPd5u8oC-EmcfTS1gdWDzTAmA26E-KkrFte9o"
                      alt={localStorage.getItem("firstName")?.charAt(0)!}
                    />
                  </i>
                ) : (
                  <i>account_circle</i>
                )}

                <div className="user-name-wrapper">
                  {localStorage.getItem("firstName")}{" "}
                  {localStorage.getItem("lastName")}
                  <p
                    style={{
                      marginBlockStart: "0px",
                    }}
                  >
                    {localStorage.getItem("email")}
                  </p>
                </div>
              </div>
              <div style={{ cursor: "pointer" }}>
                <button className="circle" onClick={() => logout()}>
                  <i>logout</i>
                  <menu className="no-wrap left user-menu"></menu>
                </button>
              </div>
            </div>
          </footer>
        </nav>
      </dialog>
    </div>
  );
};

export default Navigation;
