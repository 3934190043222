import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserSpaces, signinWithGoogle } from "../../lib/APIService";

const LandingNew = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  useEffect(() => {
    if (!!localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, []);

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const response = await signinWithGoogle(codeResponse.code);
      if (response.tokens) {
        localStorage.setItem("accessToken", response.tokens);
        localStorage.setItem("email", response.email);
        localStorage.setItem("username", response.username);
        localStorage.setItem("image", response.image);
        localStorage.setItem("firstName", response.firstName);
        localStorage.setItem("lastName", response.lastName);
        localStorage.setItem("role", response.role);
        const spaces = await getUserSpaces();
        if (
          spaces.organizations?.length &&
          spaces.organizations[0].workspaces?.length
        ) {
          localStorage.setItem(
            "workspace_id",
            spaces.organizations[0].workspaces[0].id
          );
        }
        navigate("/home/chat");
      }
      console.log(response);
    },
  });

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(120deg, #f5f7fa 0%, #e4e8f0 100%)",
      }}
    >
      <Container size="xs" px="xs">
        <Paper
          radius="md"
          p="xl"
          withBorder
          sx={{
            backgroundColor: "white",
            boxShadow: "0 8px 30px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Stack spacing="xl" align="center">
            <Box
              sx={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Text
                component="div"
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#0C2D48",
                  display: "inline-block",
                  "& span": {
                    color: "#1785E6",
                  },
                }}
                className="logo"
              >
                Fusion<span>Security</span>.ai
              </Text>
            </Box>

            <Stack spacing="xs" align="center">
              <Title order={3} color="#072D57" fw={400} align="center">
                Welcome Back
              </Title>
              <Text color="dimmed" size="sm" align="center">
                Sign in with your Google account to continue
              </Text>
            </Stack>

            <Button
              fullWidth
              size="md"
              radius="md"
              onClick={() => login()}
              leftIcon={
                <Image
                  src="/google-icon.svg"
                  width={18}
                  height={18}
                  alt="Google"
                  sx={{ display: "inline-block" }}
                />
              }
              sx={{
                backgroundColor: " #0C2D48",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: "#145DA0",
                  transform: "translateY(-2px)",
                },
              }}
            >
              Sign in with Google
            </Button>

            <Image
              src="/landingnew-2.png"
              width={280}
              alt="Dashboard Preview"
              sx={{
                borderRadius: "8px",
                opacity: 0.9,
                filter: "drop-shadow(0 4px 10px rgba(0,0,0,0.1))",
              }}
            />

            <Text size="xs" color="dimmed" align="center">
              Powered by Agentic AI • Secure and Private
            </Text>
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default LandingNew;
